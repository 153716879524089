@tailwind base;
@tailwind components;
@tailwind utilities;

@import '~react-big-calendar/lib/css/react-big-calendar.css';

@font-face {
  font-family: "SpaceMono-Regular";
  src: url("./assets/fonts/SpaceMono-Regular.ttf");
}

@font-face {
  font-family: "SpaceMono-Bold";
  src: url("./assets/fonts/SpaceMono-Bold.ttf");
}

body {
  margin: 0;
  font-family: 'SpaceMono-Regular',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.line {
  fill: none;
  stroke: steelblue;
  stroke-width: 2px;
}

div.tooltip {
  position: absolute;
  padding: 10px;
  font: 12px sans-serif;
  border-radius: 8px;
  pointer-events: none;
}

.dark .ant-progress-outer > .ant-progress-inner {
  border: 0.818257px solid #9CA3AF;
}

.dark .ant-progress-text {
  color: white;
}

.dark .ant-table-thead > tr > th {
  background-color: #232323 !important;
  color: white;
}

.ant-table-tbody > tr.ant-table-row:hover > td, .ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: unset;
}

.dark .ant-table-tbody > tr:nth-child(odd) {
  color: white;
  background-color: #232323;
}

.dark .ant-table-tbody > tr:nth-child(even) {
  color: white;
  background-color: #2D2D2D;
}

.dark .ant-table-tbody > tr > td {
  border-bottom-color: #373737;
}

.dark .dit-select__control {
  background-color: #1D1D1D;
}

.dark .ant-picker {
  background-color: #1D1D1D;
}

.dark .ant-picker input {
  color: white;
}

.dark .ant-picker-range-separator span {
  color: #D1D5DB;
}

.dark .ant-picker-suffix {
  color: #D1D5DB;
}

.rbc-calendar .rbc-month-view {
  border-radius: 8px;
}

.dark .rbc-calendar > div:nth-child(2) {
  background-color: #232323;
}

.dark .rbc-toolbar .rbc-btn-group:last-child button {
  border: none;
}

.dark .rbc-toolbar .rbc-btn-group:first-child button {
  background-color: #232323;
  border-radius: 6px;
  color: #D1D5DB;
  border: 1px solid #373737;
}

.dark .rbc-toolbar .rbc-toolbar-label {
  color: #D1D5DB;
}

.dark .rbc-toolbar .rbc-btn-group:last-child button:hover {
  background-color: #373737;
  border-radius: 6px;
  color: #D1D5DB;
}

.dark .rbc-toolbar .rbc-btn-group:last-child button.rbc-active {
  background-color: #373737;
  border-radius: 6px;
  color: #D1D5DB;
}

.dark .rbc-month-view {
  border: none;
}

.dark .rbc-month-view .rbc-header {
  color: #D1D5DB;
  border: none;
}

.dark .rbc-month-view .rbc-month-row {
  border-top: 2px solid #373737;
}

.dark .rbc-month-view .rbc-row.rbc-month-header .rbc-header span {
  color: #D1D5DB;
}

.dark .rbc-month-view .rbc-row.rbc-month-header .rbc-header {
  border-bottom: none;
}

.dark .rbc-month-view .rbc-row.rbc-month-header .rbc-header + .rbc-header {
  border-left: none;
}

.dark .rbc-month-view .rbc-row-bg .rbc-day-bg + .rbc-day-bg {
  border-left: 2px solid #373737;
}

.dark .rbc-month-view .rbc-row-bg .rbc-day-bg.rbc-off-range-bg {
  background-color: #232323 !important;
}

.dark .rbc-month-view .rbc-row-bg .rbc-day-bg .rbc-today {
  background-color: #232323;
}

.dark .rbc-month-view .rbc-row-content .rbc-row .rbc-date-cell {
  color: #D1D5DB;
}

.dark .rbc-month-view .rbc-row-content .rbc-row .rbc-date-cell .rbc-off-range {
  color: #D1D5DB;
}

.dark .rbc-time-view {
  border: none;
}

.dark .rbc-time-view .rbc-time-header.rbc-overflowing {
  border-right: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-header .rbc-time-header-content {
  border-left: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-row.rbc-time-header-cell .rbc-header {
  border-bottom: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-row.rbc-time-header-cell .rbc-header button {
  color: #D1D5DB;
}

.dark .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-row.rbc-time-header-cell .rbc-header.rbc-today {
  background-color: #2D2D2D;
}

.dark .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-row.rbc-time-header-cell .rbc-header + .rbc-header {
  border-left: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-allday-cell .rbc-row-bg .rbc-day-bg + .rbc-day-bg {
  border-left: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-header .rbc-time-header-content .rbc-allday-cell .rbc-row-bg .rbc-day-bg.rbc-today {
  background-color: #2D2D2D;
}

.dark .rbc-time-view .rbc-time-content {
  border-top: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-content > * + * > * {
  border-left: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-content .rbc-time-gutter .rbc-timeslot-group {
  border-bottom: 2px solid #373737;
}

.dark .rbc-time-view .rbc-time-content .rbc-time-gutter .rbc-timeslot-group .rbc-time-slot span {
  color: #D1D5DB;
}

.dark .rbc-time-view .rbc-time-content .rbc-day-slot.rbc-today {
  background-color: #2D2D2D;
}

.dark .rbc-time-view .rbc-time-content .rbc-day-slot .rbc-timeslot-group {
  border-bottom: 0.1rem solid #373737;
}

.dark .rbc-time-view .rbc-time-content .rbc-day-slot .rbc-timeslot-group .rbc-time-slot {
  border-top: 0.1rem solid #373737;
}

.dark .rbc-time-view .rbc-time-content .rbc-day-slot .rbc-events-container .rbc-event .rbc-event-label {
  display: none !important;
}

.account-categories-container .ant-space-item {
  width: 33.33%;
}

.one-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.Toastify__toast {
  min-height: unset;
  padding: 0px;
}

.Toastify__toast-body {
  padding: 0px;
}

.Toastify__close-button {
  display: none;
}

.revtron-logo-loading {
  margin: 0;
  position: absolute;
  top: 30%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}